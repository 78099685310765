<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader> Usuario: {{ user.name }} </CCardHeader>
        <CCardBody>
          <h1>{{ user.name }}</h1>
          <br />
          <table class="table table-striped">
            <tbody>
              <tr>
                <td><strong>Id</strong></td>
                <td>{{ user.id }}</td>
              </tr>
              <tr>
                <td><strong>Email</strong></td>
                <td>{{ user.email }}</td>
              </tr>
              <tr>
                <td><strong>Roles</strong></td>
                <td>
                  <template v-for="rol in user.roles"
                    ><v-chip>{{ rol.name }}</v-chip
                    >&nbsp;</template
                  ><span v-if="user.roles && !user.roles.length"
                    >No tiene roles asignados</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </CCardBody>
        <CCardFooter class="text-right">
          <CButton color="secondary" @click="goBack">Volver</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "User",
  data() {
    return {
      user: {
        id: "",
        name: "",
        email: "",
      },
    };
  },
  computed: {
    ...mapState(["permissions"]),
    fields() {
      return [
        { key: "key", label: this.user.name, _style: "width:150px" },
        { key: "value", label: this.user.email },
      ];
    },
  },
  mounted() {
    if (!this.permissions.includes("Gestión de Usuarios"))
      this.$router.push("/dashboard");
    
    if (localStorage.getItem("datos_usuario")) {
      this.user = JSON.parse(localStorage.getItem("datos_usuario"));
    }
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/users" });
    },
  },
};
</script>
